import { useMutation, useQuery } from '@apollo/client';
import { message, Spin } from 'antd';
import { FormApi } from 'final-form';
import React from 'react';
import { Form } from 'react-final-form';

import arrayMutators from 'final-form-arrays';
import { AddTradingPartnerStepper } from './components/AddTradingPartnerStepper/AddTradingPartnerStepper';
import { useEnumValue } from 'common/useEnumValue';
import { UpsertTradingPartnerDocument } from 'pages/TradingPartnersPage/gql/__generated__/upsertTradingPartner.mutation';
import {
  AssortmentConfigFieldSlug,
  AssortmentConfigValidationType,
  AssortmentFieldInput
} from 'graphql/__generated__/types';
import {
  DEFAULT_FORM_FIELDS_ORDER_CONFIG_EDITING,
  DEFAULT_FORM_FIELDS_ORDER_ON_FORM
} from 'pages/AssortmentPage/fieldsHelper';
import { getDefaultFieldTitles } from 'components/ChangeHistory/helpers/baseHelper';
import { TradingPartnerFallbacksDocument } from 'pages/TradingPartnersPage/gql/__generated__/tradingPartnerFallbacks.query';
import { TradingPartner } from '../TradingPartnerViewDrawer/TradingPartnerViewDrawer';
import { EditTradingPartnerTabs } from './components/EditTradingPartnerTabs/EditTradingPartnerTabs';
import { StringParam, useQueryParam } from 'use-query-params';
import { ShipTo } from './components/AddShipTosStep/AddShipTosStep';
import { get } from 'lodash-es';
import { useFeatureFlag } from 'common/useFeatureFlags/useFeatureFlags';

interface EditingTradingPartnerModalProps {
  tradingPartner?: TradingPartner;
  onClose: () => void;
}

export type AssortmentFormField = NonNullable<TradingPartner['assortmentConfig']>['fields'][number];

export interface FormField extends AssortmentFormField {
  validate: boolean;
  active: boolean;
}

const sortSlugsByDefault = (slugs: string[] | undefined, order?: string[]) => {
  if (!slugs) return undefined;
  return [...slugs].sort((s1, s2) => {
    if (!s1) return 1;
    if (!s2) return -1;
    if (!(order ?? DEFAULT_FORM_FIELDS_ORDER_CONFIG_EDITING).includes(s1)) return 1;
    if (!(order ?? DEFAULT_FORM_FIELDS_ORDER_CONFIG_EDITING).includes(s2)) return -1;
    return (
      (order ?? DEFAULT_FORM_FIELDS_ORDER_CONFIG_EDITING).indexOf(s1) -
      (order ?? DEFAULT_FORM_FIELDS_ORDER_CONFIG_EDITING).indexOf(s2)
    );
  });
};

export const EditTradingPartnerModal = ({
  onClose,
  tradingPartner
}: EditingTradingPartnerModalProps) => {
  const { enumValues } = useEnumValue('AssortmentConfigFieldSlug');

  const { enabled: useNewAssortment } = useFeatureFlag({
    name: 'schip_assortment_v3_management_for_tp',
    tradingPartnerExternalId: tradingPartner?.externalId
  });

  const { data: fallbacksData, loading: loadingFallbacksData } = useQuery(
    TradingPartnerFallbacksDocument
  );

  const [firstOpenTab] = useQueryParam('editTab', StringParam);

  const [upsertTradingPartner, { loading: upserting }] = useMutation(UpsertTradingPartnerDocument, {
    refetchQueries: ['tradingPartners']
  });

  const submitForm = async (values: any, form: FormApi<any, any>) => {
    try {
      const {
        assortmentConfig,
        businessUnitId,
        deliveryType,
        externalId,
        id,
        measurementSystem,
        name,
        purchaseOrderAcknowledgementTransport,
        sendInitialPurchaseOrderAcknowledgment,
        sendPurchaseOrderProcessingState,
        shipToReleaseIdentifier,
        orderItemRounding,
        vendorMarketId,
        // processing flow
        autoRelease,
        ingestPo,
        cancelPo,
        resetPo,
        releaseExchangeReturn,
        releaseAcknowledgement,
        releaseShipment,
        releaseInvoice,
        fulfillmentSync,
        amazonSellingPartnerConfigEditClientSecret,
        amazonSellingPartnerConfigEditRefreshToken,
        amazonSellingPartnerConfigEditSecretAccessKey,
        samsClubConfigEditClientSecret,
        releaseInventory
      } = values;
      const inputValues = {
        id,
        externalId,
        name,
        vendorMarketId,
        businessUnitId,
        deliveryType,
        sendInitialPurchaseOrderAcknowledgment,
        sendPurchaseOrderProcessingState,
        incomingUnitOfMeasurementOverride: values.incomingUnitOfMeasurementOverride?.trim() || null,
        internalProductIdQualifier: values.internalProductIdQualifier?.trim() || null,
        outgoingUnitOfMeasurementOverride: values.outgoingUnitOfMeasurementOverride?.trim() || null,
        productIdQualifier: values.productIdQualifier?.trim() || null,
        purchaseOrderAcknowledgementTransport,
        releaseMethod: values.releaseMethod?.trim() || null,
        shipToReleaseIdentifier,
        orderItemRounding,
        // send RDDs only on adding
        tradingPartnerRdds: values.id
          ? undefined
          : values.rdds
              .filter((shipToObj: { shipTo: ShipTo; selected: boolean }) => shipToObj.selected)
              .map((shipToObj: { shipTo: ShipTo; selected: boolean }) => ({
                retailerDeliveryDestinationId: shipToObj.shipTo.id
              })),
        shippingConfig: values.useShippingConfig
          ? {
              tenderingEnabled: !!values.shippingConfigTenderingEnabled,
              routingEnabled: !!values.shippingConfigRoutingEnabled,
              maximalPickupDays: Number(values.shippingConfigMaximalPickupDays),
              minimalPickupDays: Number(values.shippingConfigMinimalPickupDays),
              availableCarrierCodes: values.shippingConfigAvailableCarrierCodes,
              sla: values.shippingConfigSla || null
            }
          : null,
        pepdirectConfig: values.usePepdirectConfig
          ? {
              appId: values.pepdirectConfigAppId
            }
          : null,
        channelAdvisorConfig: values.useChannelAdvisorConfig
          ? {
              privateNotes: values.channelAdvisorConfigPrivateNotes?.trim() || null,
              profileId: Number(values.channelAdvisorConfigProfileId),
              siteId: Number(values.channelAdvisorConfigSiteId),
              siteName: values.channelAdvisorConfigSiteName,
              specialInstructionsPostfix:
                values.channelAdvisorConfigSpecialInstructionsPostfix?.trim() || null,
              specialInstructionsPrefix:
                values.channelAdvisorConfigSpecialInstructionsPrefix?.trim() || null
            }
          : null,
        ochConfig: values.useOchConfig
          ? {
              scope: values.ochConfigScope,
              sourceSystemId: Number(values.ochConfigSourceSystemId),
              region: values.ochConfigRegion
            }
          : null,
        ediConfigInternal: values.useEdiConfigInternal
          ? {
              format: values.ediConfigInternalFormat?.trim() || null,
              gsLocalCode: values.ediConfigInternalGsLocalCode,
              gsRemoteCode: values.ediConfigInternalGsRemoteCode,
              interchangeUsageIndicator:
                values.ediConfigInternalInterchangeUsageIndicator?.trim() || null,
              isaComponentElementSeparator:
                values.ediConfigInternalIsaComponentElementSeparator?.trim() || null,
              isaInterchangeControlVersionNumber:
                values.ediConfigInternalIsaInterchangeControlVersionNumber?.trim() || null,
              isaLocalId: values.ediConfigInternalIsaLocalId,
              isaLocalQualifier: values.ediConfigInternalIsaLocalQualifier,
              isaRemoteId: values.ediConfigInternalIsaRemoteId,
              isaRemoteQualifier: values.ediConfigInternalIsaRemoteQualifier,
              isaRepetitionSeparator:
                values.ediConfigInternalIsaRepetitionSeparator?.trim() || null,
              supplierId: values.ediConfigInternalSupplierId?.trim() || null,
              supplierIdPath: values.ediConfigInternalSupplierIdPath?.trim() || null,
              webhookEndpoint: values.ediConfigInternalWebhookEndpoint?.trim() || null
            }
          : null,

        ediSelfServiceConfig: values.useEdiMessageConfiguration
          ? {
              ediGlobalConfig: {
                externalProductIdQualifier: values.ediMessageConfigurationExternalProductId,
                internalProductIdQualifier: values.ediMessageConfigurationInternalProductId,
                format: values.ediMessageConfigurationFormat?.trim(),
                gsLocalId: values.ediMessageConfigurationGsLocalCode,
                gsRemoteId: values.ediMessageConfigurationGsRemoteCode,
                isaInterchangeUsageIndicator:
                  values.ediMessageConfigurationIsaInterchangeUsageIndicator?.trim(),
                isaComponentElementSeparator:
                  values.ediMessageConfigurationIsaComponentElementSeparator?.trim(),
                isaInterchangeControlVersion:
                  values.ediMessageConfigurationIsaInterchangeControlVersion?.trim(),
                isaLocalId: values.ediMessageConfigurationIsaLocalId,
                isaLocalQualifier: values.ediMessageConfigurationIsaLocalQualifier,
                isaRemoteId: values.ediMessageConfigurationIsaRemoteId,
                isaRemoteQualifier: values.ediMessageConfigurationIsaRemoteQualifier,
                isaRepetitionSeparator:
                  values.ediMessageConfigurationIsaRepetitionSeparator?.trim(),
                supplierId: values.ediMessageConfigurationSupplierId?.trim(),
                supplierIdPath: values.ediMessageConfigurationSupplierIdPath?.trim(),
                webhookEndpoint: values.ediMessageConfigurationWebhookEndpoint?.trim()
              },
              ediMessageConfig:
                values.usePurchaseOrderCustomization850 ||
                values.usePurchaseOrderAckCustomization855 ||
                values.usePurchaseOrderCustomization856 ||
                values.usePurchaseOrderCustomization810
                  ? {
                      edi850Config: values.usePurchaseOrderCustomization850
                        ? {
                            shipToPath: values.purchaseOrderCustomization850ConfigShipTo,
                            productUnitOfMeasurement:
                              values.purchaseOrderCustomization850ConfigProductUnit,
                            passThroughUpcToBu: values.purchaseOrderCustomization850ConfigPassUpc
                          }
                        : null,
                      edi855Config: values.usePurchaseOrderAckCustomization855
                        ? {
                            ignoreAdditionalItems:
                              values.purchaseOrderAckCustomization855ConfigCustomSwitch,
                            mtx02UnavailableItem:
                              values.purchaseOrderAckCustomization855ConfigItemNotAvailInput || '',
                            mtx02InvalidItem:
                              values.purchaseOrderAckCustomization855ConfigItemInvalidInput || '',
                            match850Sequence: values.purchaseOrderAckCustomization855ConfigMatch,
                            releaseDocumentToTradingPartner:
                              values.purchaseOrderAckCustomization855ConfigReleaseDocument,
                            ediAdditionalItemConfig:
                              values.purchaseOrderAckCustomization855ConfigCustom.map(
                                (purchaseOrderAckCustomization855CustomElement: {
                                  position: number;
                                  inputTextNull: boolean;
                                  inputText: string;
                                }) => ({
                                  position: purchaseOrderAckCustomization855CustomElement.position,
                                  value: purchaseOrderAckCustomization855CustomElement.inputTextNull
                                    ? null
                                    : purchaseOrderAckCustomization855CustomElement.inputText
                                })
                              )
                          }
                        : null,
                      edi856Config: values.usePurchaseOrderCustomization856
                        ? {
                            match850Sequence: values.purchaseOrderCustomization856ConfigMatch,
                            releaseDocumentToTradingPartner:
                              values.purchaseOrderCustomization856ConfigReleaseDocument,
                            passThroughShipToFromBu:
                              values.purchaseOrderCustomization856ConfigPassShipTo,
                            ignoreAdditionalItems:
                              values.purchaseOrderCustomization856ConfigCustomSwitch,
                            ctt01TargetSegmentCount:
                              values.purchaseOrderCustomization856ConfigCttCountMatch,
                            ediAdditionalItemConfig:
                              values.purchaseOrderCustomization856ConfigCustom.map(
                                (edi856ConfigCustom: {
                                  position: number;
                                  inputTextNull: boolean;
                                  inputText: string;
                                }) => ({
                                  position: edi856ConfigCustom.position,
                                  value: edi856ConfigCustom.inputTextNull
                                    ? null
                                    : edi856ConfigCustom.inputText
                                })
                              )
                          }
                        : null,
                      edi810Config: values.usePurchaseOrderCustomization810
                        ? {
                            n1Remittance: values.purchaseOrderCustomization810ConfigResName || '',
                            n3Remittance:
                              values.purchaseOrderCustomization810ConfigResAddress || '',
                            n4Remittance:
                              values.purchaseOrderCustomization810ConfigResCityZip || '',
                            releaseDocumentToTradingPartner:
                              values.purchaseOrderCustomization810ConfigReleaseDocument,
                            ignoreAdditionalItems:
                              values.purchaseOrderCustomization810ConfigCustomSwitch,
                            ediAdditionalItemConfig:
                              values.purchaseOrderCustomization810ConfigCustom.map(
                                (edi810ConfigCustom: {
                                  position: number;
                                  inputTextNull: boolean;
                                  inputText: string;
                                }) => ({
                                  position: edi810ConfigCustom.position,
                                  value: edi810ConfigCustom.inputTextNull
                                    ? null
                                    : edi810ConfigCustom.inputText
                                })
                              ),
                            match850Sequence: values.purchaseOrderCustomization810ConfigMatch,
                            passRemittanceToBu:
                              values.purchaseOrderCustomization810ConfigPassRemAddress,
                            passThroughBillToFromBu:
                              values.purchaseOrderCustomization810ConfigPassBillToAddress
                          }
                        : null
                    }
                  : null
            }
          : null,

        ediConfigExternal: values.useEdiConfigExternal
          ? {
              format: values.ediConfigExternalFormat?.trim() || null,
              gsLocalCode: values.ediConfigExternalGsLocalCode,
              gsRemoteCode: values.ediConfigExternalGsRemoteCode,
              interchangeUsageIndicator:
                values.ediConfigExternalInterchangeUsageIndicator?.trim() || null,
              isaComponentElementSeparator:
                values.ediConfigExternalIsaComponentElementSeparator?.trim() || null,
              isaInterchangeControlVersionNumber:
                values.ediConfigExternalIsaInterchangeControlVersionNumber?.trim() || null,
              isaLocalId: values.ediConfigExternalIsaLocalId,
              isaLocalQualifier: values.ediConfigExternalIsaLocalQualifier,
              isaRemoteId: values.ediConfigExternalIsaRemoteId,
              isaRemoteQualifier: values.ediConfigExternalIsaRemoteQualifier,
              isaRepetitionSeparator:
                values.ediConfigExternalIsaRepetitionSeparator?.trim() || null,
              supplierId: values.ediConfigExternalSupplierId?.trim() || null,
              supplierIdPath: values.ediConfigExternalSupplierIdPath?.trim() || null,
              webhookEndpoint: values.ediConfigExternalWebhookEndpoint?.trim() || null
            }
          : null,
        emailConfigSalesOrderRelease: values.useEmailConfigSalesOrderRelease
          ? {
              active: !!values.emailConfigSalesOrderReleaseActive,
              emailFromAddress: values.emailConfigSalesOrderReleaseEmailFromAddress,
              emailFromName: values.emailConfigSalesOrderReleaseEmailFromName?.trim() || null,
              emailToList: values.emailConfigSalesOrderReleaseEmailToList
            }
          : null,
        emailConfigSalesOrderExport: values.useEmailConfigSalesOrderExport
          ? {
              active: !!values.emailConfigSalesOrderExportActive,
              emailFromAddress: values.emailConfigSalesOrderExportEmailFromAddress,
              emailFromName: values.emailConfigSalesOrderExportEmailFromName?.trim() || null,
              emailToList: values.emailConfigSalesOrderExportEmailToList
            }
          : null,
        internalAlertEmailConfig: values.useInternalAlertEmailConfig
          ? {
              active: !!values.internalAlertEmailConfigActive,
              emailFromAddress: values.internalAlertEmailConfigEmailFromAddress,
              emailFromName: values.internalAlertEmailConfigEmailFromName?.trim() || null,
              emailToList: values.internalAlertEmailConfigEmailToList
            }
          : null,
        internalAlertConfig: values.useInternalAlertConfig
          ? {
              sendDuplicateOrderItemsAlert: values.internalAlertConfigSendDuplicateOrderItemsAlert,
              sendInvalidOrderItemsAlert: values.internalAlertConfigSendInvalidOrderItemsAlert,
              sendLateOrdersAlert: values.internalAlertConfigSendLateOrdersAlert
            }
          : null,
        storeConfig: values.showStoreConfig
          ? {
              requiredIds: values.storeConfigRequiredIds
            }
          : null,
        measurementSystem,
        processingFlow: values.showProcessingFlow
          ? {
              autoRelease,
              ingestPo,
              preparePo: values.preparePo?.trim() || null,
              routeToOptimalDc: values.routeToOptimalDc?.trim() || null,
              updatePo: values.updatePo?.trim() || null,
              cancelPo: cancelPo ?? null,
              resetPo: resetPo ?? null,
              releaseAcknowledgement: releaseAcknowledgement ?? null,
              releaseShipment: releaseShipment ?? null,
              releaseInvoice: releaseInvoice ?? null,
              fulfillmentSync: fulfillmentSync ?? null,
              releaseExchangeReturn: releaseExchangeReturn ?? null,
              releaseInventory: releaseInventory ?? null
            }
          : null,
        poValidationConfig: values.showPoValidationConfig
          ? {
              priceMismatch: values.poValidationConfigPriceMismatch?.trim() || null,
              underMoq: values.poValidationConfigUnderMoq?.trim() || null,
              wrongUom: values.poValidationConfigWrongUom?.trim() || null
            }
          : null,
        amazonSellingPartnerConfig: values.useAmazonSellingPartnerConfig
          ? {
              accessKeyId: values.amazonSellingPartnerConfigAccessKeyId?.trim() || null,
              clientId: values.amazonSellingPartnerConfigClientId?.trim() || null,
              clientSecret:
                amazonSellingPartnerConfigEditClientSecret !== undefined
                  ? amazonSellingPartnerConfigEditClientSecret?.trim() || null
                  : undefined,
              refreshToken:
                amazonSellingPartnerConfigEditRefreshToken !== undefined
                  ? amazonSellingPartnerConfigEditRefreshToken?.trim() || null
                  : undefined,
              secretAccessKey:
                amazonSellingPartnerConfigEditSecretAccessKey !== undefined
                  ? amazonSellingPartnerConfigEditSecretAccessKey?.trim() || null
                  : undefined,
              writeApiSite: values.amazonSellingPartnerConfigWriteApiSite?.trim() || null,
              readApiSite: values.amazonSellingPartnerConfigReadApiSite?.trim() || null,
              accessTokenSite: values.amazonSellingPartnerConfigAccessTokenSite?.trim() || null,
              region: values.amazonSellingPartnerConfigRegion?.trim() || null
            }
          : null,
        samsClubConfig: values.useSamsClubConfig
          ? {
              clientId: values.samsClubConfigClientId || null,
              clientSecret:
                samsClubConfigEditClientSecret !== undefined
                  ? samsClubConfigEditClientSecret?.trim() || null
                  : undefined
            }
          : null,
        sourcingRules: values.showSourcingRules
          ? values.sourcingRules?.map((rule: any) => ({
              description: rule.description,
              distributionCenterId: rule.distributionCenterId || null,
              id: rule.id,
              maxWeightLbs: rule.maxWeightLbs ? Number(rule.maxWeightLbs) : null,
              minWeightLbs: rule.minWeightLbs ? Number(rule.minWeightLbs) : null,
              priority: rule.priority,
              releaseMethod: rule.releaseMethod
            }))
          : [],
        assortmentConfig: {
          dcFilter: !!assortmentConfig.dcFilter,
          multipleUpc: !!assortmentConfig.multipleUpc,
          specialAssortment: !!assortmentConfig.specialAssortment,
          catalogItemType: assortmentConfig.catalogItemType,
          useDcAvailabilities: !!assortmentConfig.useDcAvailabilities,
          useMultipleSubstitutions: !!assortmentConfig.useMultipleSubstitutions,
          fields: (assortmentConfig.fields as FormField[]).reduce((result, current) => {
            if (current.active) {
              result.push({
                name: current.name,
                slug: current.slug as AssortmentConfigFieldSlug,
                required: current.required,
                validation: current.validation
                  ? {
                      rules:
                        current.validation.rules?.map((rule) => ({
                          type: (rule.type as AssortmentConfigValidationType) || 'TEXT',
                          value: rule.value || null
                        })) || []
                    }
                  : undefined
              });
            }
            return result;
          }, [] as AssortmentFieldInput[])
        }
      };

      await upsertTradingPartner({
        variables: {
          input: inputValues
        }
      });
      form.reset();
      message.success(`Trading Partner successfully ${tradingPartner?.id ? 'updated' : 'added'}`);
      onClose();
    } catch (e) {
      form.initialize(values);
      console.log({ e });
      message.error(`Error happened during Trading Partner saving. ${e.message}`);
    }
  };

  return (
    <Spin spinning={upserting || loadingFallbacksData} style={{ height: '100%' }}>
      <Form
        initialValues={{
          id: tradingPartner?.id || null,
          externalId: tradingPartner?.externalId || '',
          name: tradingPartner?.name || '',
          vendorMarketId: tradingPartner?.vendorMarket?.id || null,
          businessUnitId: tradingPartner?.businessUnit?.id || null,
          deliveryType: tradingPartner?.deliveryType || null,
          sendInitialPurchaseOrderAcknowledgment:
            !!tradingPartner?.sendInitialPurchaseOrderAcknowledgment,
          sendPurchaseOrderProcessingState: !!tradingPartner?.sendPurchaseOrderProcessingState,
          incomingUnitOfMeasurementOverride: tradingPartner?.incomingUnitOfMeasurementOverride,
          internalProductIdQualifier: tradingPartner?.internalProductIdQualifier,
          outgoingUnitOfMeasurementOverride: tradingPartner?.outgoingUnitOfMeasurementOverride,
          productIdQualifier: tradingPartner?.productIdQualifier,
          purchaseOrderAcknowledgementTransport:
            tradingPartner?.purchaseOrderAcknowledgementTransport,
          releaseMethod: tradingPartner?.releaseMethod,
          shipToReleaseIdentifier: tradingPartner?.shipToReleaseIdentifier,
          orderItemRounding: tradingPartner?.orderItemRounding,
          // shipping config
          useShippingConfig: !!tradingPartner?.shippingConfig,
          shippingConfigTenderingEnabled: tradingPartner?.shippingConfig?.tenderingEnabled,
          shippingConfigRoutingEnabled: tradingPartner?.shippingConfig?.routingEnabled,
          shippingConfigMaximalPickupDays: tradingPartner?.shippingConfig?.maximalPickupDays,
          shippingConfigMinimalPickupDays: tradingPartner?.shippingConfig?.minimalPickupDays,
          shippingConfigSla: tradingPartner?.shippingConfig?.sla || null,
          shippingConfigAvailableCarrierCodes:
            tradingPartner?.shippingConfig?.availableCarrierCodes || [],
          // och config
          useOchConfig: !!tradingPartner?.ochConfig,
          ochConfigScope: tradingPartner?.ochConfig?.scope,
          ochConfigSourceSystemId: tradingPartner?.ochConfig?.sourceSystemId,
          ochConfigRegion: tradingPartner?.ochConfig?.region,
          // channel Advisor Config
          useChannelAdvisorConfig: !!tradingPartner?.channelAdvisorConfig,
          channelAdvisorConfigPrivateNotes: tradingPartner?.channelAdvisorConfig?.privateNotes,
          channelAdvisorConfigProfileId: String(tradingPartner?.channelAdvisorConfig?.profileId),
          channelAdvisorConfigSiteId: tradingPartner?.channelAdvisorConfig?.siteId,
          channelAdvisorConfigSiteName: tradingPartner?.channelAdvisorConfig?.siteName,
          channelAdvisorConfigSpecialInstructionsPostfix:
            tradingPartner?.channelAdvisorConfig?.specialInstructionsPostfix,
          channelAdvisorConfigSpecialInstructionsPrefix:
            tradingPartner?.channelAdvisorConfig?.specialInstructionsPrefix,
          // pepdirect Config
          usePepdirectConfig: !!tradingPartner?.pepdirectConfig,
          pepdirectConfigAppId: tradingPartner?.pepdirectConfig?.appId,

          // ediMessageConf
          useEdiMessageConfiguration: !!tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig,
          ediMessageConfigurationExternalProductId:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.externalProductIdQualifier,
          ediMessageConfigurationInternalProductId:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.internalProductIdQualifier,
          ediMessageConfigurationFormat:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.format,
          ediMessageConfigurationGsLocalCode:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.gsLocalId,
          ediMessageConfigurationGsRemoteCode:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.gsRemoteId,
          ediMessageConfigurationIsaInterchangeUsageIndicator:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.isaInterchangeUsageIndicator,
          ediMessageConfigurationIsaComponentElementSeparator:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.isaComponentElementSeparator,
          ediMessageConfigurationIsaInterchangeControlVersion:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.isaInterchangeControlVersion,
          ediMessageConfigurationIsaLocalId:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.isaLocalId,
          ediMessageConfigurationIsaLocalQualifier:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.isaLocalQualifier,
          ediMessageConfigurationIsaRemoteId:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.isaRemoteId,
          ediMessageConfigurationIsaRemoteQualifier:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.isaRemoteQualifier,
          ediMessageConfigurationIsaRepetitionSeparator:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.isaRepetitionSeparator,
          ediMessageConfigurationSupplierId:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.supplierId,
          ediMessageConfigurationSupplierIdPath:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.supplierIdPath,
          ediMessageConfigurationWebhookEndpoint:
            tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig?.webhookEndpoint,

          // purchaseOrderCustomization850
          usePurchaseOrderCustomization850:
            !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi850Config,
          purchaseOrderCustomization850ConfigShipTo:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi850Config?.shipToPath,
          purchaseOrderCustomization850ConfigProductUnit:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi850Config
              ?.productUnitOfMeasurement,
          purchaseOrderCustomization850ConfigPassUpc:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi850Config
              ?.passThroughUpcToBu || false,

          //purchaseOrderAckCustomization855
          usePurchaseOrderAckCustomization855:
            !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi855Config,
          purchaseOrderAckCustomization855ConfigCustomSwitch:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi855Config
              ?.ignoreAdditionalItems === undefined
              ? true
              : tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi855Config
                  ?.ignoreAdditionalItems,
          purchaseOrderAckCustomization855ConfigItemNotAvailInput:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi855Config
              ?.mtx02UnavailableItem,
          purchaseOrderAckCustomization855ConfigItemInvalidInput:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi855Config?.mtx02InvalidItem,
          purchaseOrderAckCustomization855ConfigMatch:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi855Config
              ?.match850Sequence || false,
          purchaseOrderAckCustomization855ConfigReleaseDocument:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi855Config
              ?.releaseDocumentToTradingPartner || false,
          purchaseOrderAckCustomization855ConfigCustom: (
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi855Config
              ?.ediAdditionalItemConfig || []
          ).map((edi855ConfigCustom) => ({
            position: edi855ConfigCustom?.position,
            inputText: edi855ConfigCustom?.value,
            inputTextNull: edi855ConfigCustom?.value === null
          })),

          //purchaseOrderCustomization856
          usePurchaseOrderCustomization856:
            !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi856Config,
          purchaseOrderCustomization856ConfigMatch:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi856Config
              ?.match850Sequence || false,
          purchaseOrderCustomization856ConfigReleaseDocument:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi856Config
              ?.releaseDocumentToTradingPartner || false,
          purchaseOrderCustomization856ConfigPassShipTo:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi856Config
              ?.passThroughShipToFromBu || false,
          purchaseOrderCustomization856ConfigCttCountMatch:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi856Config
              ?.ctt01TargetSegmentCount || 'HL',
          purchaseOrderCustomization856ConfigCustomSwitch:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi856Config
              ?.ignoreAdditionalItems === undefined
              ? true
              : tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi856Config
                  ?.ignoreAdditionalItems,
          purchaseOrderCustomization856ConfigCustom: (
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi856Config
              ?.ediAdditionalItemConfig || []
          ).map((edi856ConfigCustom) => ({
            position: edi856ConfigCustom?.position,
            inputText: edi856ConfigCustom?.value,
            inputTextNull: edi856ConfigCustom?.value === null
          })),

          //purchaseOrderCustomization810
          usePurchaseOrderCustomization810:
            !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config,
          purchaseOrderCustomization810ConfigReleaseDocument:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
              ?.releaseDocumentToTradingPartner || false,
          purchaseOrderCustomization810ConfigResName:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config?.n1Remittance,
          purchaseOrderCustomization810ConfigResAddress:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config?.n3Remittance,
          purchaseOrderCustomization810ConfigResCityZip:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config?.n4Remittance,
          purchaseOrderCustomization810ConfigCustomSwitch:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
              ?.ignoreAdditionalItems === undefined
              ? true
              : tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
                  ?.ignoreAdditionalItems,
          purchaseOrderCustomization810ConfigCustom: (
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
              ?.ediAdditionalItemConfig || []
          ).map((edi810ConfigCustom) => ({
            position: edi810ConfigCustom?.position,
            inputText: edi810ConfigCustom?.value,
            inputTextNull: edi810ConfigCustom?.value === null
          })),
          purchaseOrderCustomization810ConfigMatch:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
              ?.match850Sequence || false,
          purchaseOrderCustomization810ConfigPassRemAddress:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
              ?.passRemittanceToBu || true,
          purchaseOrderCustomization810ConfigPassBillToAddress:
            tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
              ?.passThroughBillToFromBu || true,

          // ediConfigInternal
          useEdiConfigInternal: !!tradingPartner?.ediConfigInternal,
          ediConfigInternalFormat: tradingPartner?.ediConfigInternal?.format,
          ediConfigInternalGsLocalCode: tradingPartner?.ediConfigInternal?.gsLocalCode,
          ediConfigInternalGsRemoteCode: tradingPartner?.ediConfigInternal?.gsRemoteCode,
          ediConfigInternalInterchangeUsageIndicator:
            tradingPartner?.ediConfigInternal?.interchangeUsageIndicator,
          ediConfigInternalIsaComponentElementSeparator:
            tradingPartner?.ediConfigInternal?.isaComponentElementSeparator,
          ediConfigInternalIsaInterchangeControlVersionNumber:
            tradingPartner?.ediConfigInternal?.isaInterchangeControlVersionNumber,
          ediConfigInternalIsaLocalId: tradingPartner?.ediConfigInternal?.isaLocalId,
          ediConfigInternalIsaLocalQualifier: tradingPartner?.ediConfigInternal?.isaLocalQualifier,
          ediConfigInternalIsaRemoteId: tradingPartner?.ediConfigInternal?.isaRemoteId,
          ediConfigInternalIsaRemoteQualifier:
            tradingPartner?.ediConfigInternal?.isaRemoteQualifier,
          ediConfigInternalIsaRepetitionSeparator:
            tradingPartner?.ediConfigInternal?.isaRepetitionSeparator,
          ediConfigInternalSupplierId: tradingPartner?.ediConfigInternal?.supplierId,
          ediConfigInternalSupplierIdPath: tradingPartner?.ediConfigInternal?.supplierIdPath,
          ediConfigInternalWebhookEndpoint: tradingPartner?.ediConfigInternal?.webhookEndpoint,
          // ediConfigExternal
          useEdiConfigExternal: !!tradingPartner?.ediConfigExternal,
          ediConfigExternalFormat: tradingPartner?.ediConfigExternal?.format,
          ediConfigExternalGsLocalCode: tradingPartner?.ediConfigExternal?.gsLocalCode,
          ediConfigExternalGsRemoteCode: tradingPartner?.ediConfigExternal?.gsRemoteCode,
          ediConfigExternalInterchangeUsageIndicator:
            tradingPartner?.ediConfigExternal?.interchangeUsageIndicator,
          ediConfigExternalIsaComponentElementSeparator:
            tradingPartner?.ediConfigExternal?.isaComponentElementSeparator,
          ediConfigExternalIsaInterchangeControlVersionNumber:
            tradingPartner?.ediConfigExternal?.isaInterchangeControlVersionNumber,
          ediConfigExternalIsaLocalId: tradingPartner?.ediConfigExternal?.isaLocalId,
          ediConfigExternalIsaLocalQualifier: tradingPartner?.ediConfigExternal?.isaLocalQualifier,
          ediConfigExternalIsaRemoteId: tradingPartner?.ediConfigExternal?.isaRemoteId,
          ediConfigExternalIsaRemoteQualifier:
            tradingPartner?.ediConfigExternal?.isaRemoteQualifier,
          ediConfigExternalIsaRepetitionSeparator:
            tradingPartner?.ediConfigExternal?.isaRepetitionSeparator,
          ediConfigExternalSupplierId: tradingPartner?.ediConfigExternal?.supplierId,
          ediConfigExternalSupplierIdPath: tradingPartner?.ediConfigExternal?.supplierIdPath,
          ediConfigExternalWebhookEndpoint: tradingPartner?.ediConfigExternal?.webhookEndpoint,
          // emailConfigSalesOrderRelease
          useEmailConfigSalesOrderRelease: !!tradingPartner?.emailConfigSalesOrderRelease,
          emailConfigSalesOrderReleaseActive: tradingPartner?.emailConfigSalesOrderRelease?.active,
          emailConfigSalesOrderReleaseEmailFromAddress:
            tradingPartner?.emailConfigSalesOrderRelease?.emailFromAddress,
          emailConfigSalesOrderReleaseEmailFromName:
            tradingPartner?.emailConfigSalesOrderRelease?.emailFromName,
          emailConfigSalesOrderReleaseEmailToList:
            tradingPartner?.emailConfigSalesOrderRelease?.emailToList || [],
          // emailConfigSalesOrderExport
          useEmailConfigSalesOrderExport: !!tradingPartner?.emailConfigSalesOrderExport,
          emailConfigSalesOrderExportActive: tradingPartner?.emailConfigSalesOrderExport?.active,
          emailConfigSalesOrderExportEmailFromAddress:
            tradingPartner?.emailConfigSalesOrderExport?.emailFromAddress,
          emailConfigSalesOrderExportEmailFromName:
            tradingPartner?.emailConfigSalesOrderExport?.emailFromName,
          emailConfigSalesOrderExportEmailToList:
            tradingPartner?.emailConfigSalesOrderExport?.emailToList || [],
          // internalAlertEmailConfig
          useInternalAlertEmailConfig: !!tradingPartner?.internalAlertEmailConfig,
          internalAlertEmailConfigActive: tradingPartner?.internalAlertEmailConfig?.active,
          internalAlertEmailConfigEmailFromAddress:
            tradingPartner?.internalAlertEmailConfig?.emailFromAddress,
          internalAlertEmailConfigEmailFromName:
            tradingPartner?.internalAlertEmailConfig?.emailFromName,
          internalAlertEmailConfigEmailToList:
            tradingPartner?.internalAlertEmailConfig?.emailToList || [],
          // internalAlertConfig
          useInternalAlertConfig: !!tradingPartner?.internalAlertConfig,
          internalAlertConfigSendDuplicateOrderItemsAlert:
            !!tradingPartner?.internalAlertConfig?.sendDuplicateOrderItemsAlert,
          internalAlertConfigSendInvalidOrderItemsAlert:
            !!tradingPartner?.internalAlertConfig?.sendInvalidOrderItemsAlert,
          internalAlertConfigSendLateOrdersAlert:
            !!tradingPartner?.internalAlertConfig?.sendLateOrdersAlert,
          // storeConfig
          showStoreConfig: true,
          storeConfigRequiredIds: [...(tradingPartner?.storeConfig?.requiredIds || [])].sort(
            (id) =>
              tradingPartner?.shipToReleaseIdentifier &&
              tradingPartner?.shipToReleaseIdentifier === id
                ? -1
                : 1
          ),
          measurementSystem: tradingPartner?.measurementSystem,
          // processing flow
          autoRelease: !!tradingPartner?.processingFlow?.autoRelease,
          showProcessingFlow: !!tradingPartner?.processingFlow,
          ingestPo: tradingPartner?.processingFlow?.ingestPo,
          preparePo: tradingPartner?.processingFlow?.preparePo,
          routeToOptimalDc: tradingPartner?.processingFlow?.routeToOptimalDc,
          updatePo: tradingPartner?.processingFlow?.updatePo,
          cancelPo: tradingPartner?.processingFlow?.cancelPo,
          resetPo: tradingPartner?.processingFlow?.resetPo,
          releaseAcknowledgement: tradingPartner?.processingFlow?.releaseAcknowledgement,
          releaseShipment: tradingPartner?.processingFlow?.releaseShipment,
          releaseInvoice: tradingPartner?.processingFlow?.releaseInvoice,
          fulfillmentSync: tradingPartner?.processingFlow?.fulfillmentSync,
          releaseExchangeReturn: tradingPartner?.processingFlow?.releaseExchangeReturn,
          releaseInventory: tradingPartner?.processingFlow?.releaseInventory,
          // validation config
          showPoValidationConfig: !!tradingPartner?.poValidationConfig,
          poValidationConfigPriceMismatch: tradingPartner?.poValidationConfig?.priceMismatch,
          poValidationConfigUnderMoq: tradingPartner?.poValidationConfig?.underMoq,
          poValidationConfigWrongUom: tradingPartner?.poValidationConfig?.wrongUom,
          // amazon Selling Partner Config
          useAmazonSellingPartnerConfig: !!tradingPartner?.amazonSellingPartnerConfig,
          amazonSellingPartnerConfigAccessKeyId:
            tradingPartner?.amazonSellingPartnerConfig?.accessKeyId,
          amazonSellingPartnerConfigClientId: tradingPartner?.amazonSellingPartnerConfig?.clientId,
          amazonSellingPartnerConfigClientSecret:
            tradingPartner?.amazonSellingPartnerConfig?.clientSecret,
          amazonSellingPartnerConfigRefreshToken:
            tradingPartner?.amazonSellingPartnerConfig?.refreshToken,
          amazonSellingPartnerConfigSecretAccessKey:
            tradingPartner?.amazonSellingPartnerConfig?.secretAccessKey,
          amazonSellingPartnerConfigWriteApiSite:
            tradingPartner?.amazonSellingPartnerConfig?.writeApiSite,
          amazonSellingPartnerConfigReadApiSite:
            tradingPartner?.amazonSellingPartnerConfig?.readApiSite,
          amazonSellingPartnerConfigAccessTokenSite:
            tradingPartner?.amazonSellingPartnerConfig?.accessTokenSite,
          amazonSellingPartnerConfigRegion: tradingPartner?.amazonSellingPartnerConfig?.region,
          // sam's club config
          useSamsClubConfig: !!tradingPartner?.samsClubConfig,
          samsClubConfigClientId: tradingPartner?.samsClubConfig?.clientId,
          samsClubConfigClientSecret: tradingPartner?.samsClubConfig?.clientSecret,
          // sourcing/release rules
          showSourcingRules: (tradingPartner?.sourcingRules?.length || 0) > 0,
          sourcingRules: tradingPartner?.sourcingRules?.map((rule) => ({
            description: rule.description,
            distributionCenterId: rule.distributionCenter?.id,
            id: rule.id,
            maxWeightLbs:
              rule.maxWeightLbs !== null && rule.maxWeightLbs !== undefined
                ? `${rule.maxWeightLbs}`
                : '',
            minWeightLbs:
              rule.minWeightLbs !== null && rule.minWeightLbs !== undefined
                ? `${rule.minWeightLbs}`
                : '',
            priority: rule.priority,
            releaseMethod: rule.releaseMethod,
            key: rule.id
          })),
          // assortment config
          assortmentConfig: {
            ...tradingPartner?.assortmentConfig,
            fields: sortSlugsByDefault(
              enumValues,
              useNewAssortment
                ? DEFAULT_FORM_FIELDS_ORDER_CONFIG_EDITING
                : DEFAULT_FORM_FIELDS_ORDER_ON_FORM
            )?.map((value) => {
              const exist = tradingPartner?.assortmentConfig?.fields.find(
                (field) => field.slug === value
              );
              const active = value === 'NAME' || (!useNewAssortment && value === 'UPC') || !!exist;
              const required =
                value === 'NAME' || (!useNewAssortment && value === 'UPC') || !!exist?.required;
              return exist
                ? {
                    ...exist,
                    active,
                    required,
                    validate: !!exist.validation
                  }
                : {
                    slug: value,
                    name: getDefaultFieldTitles(value),
                    required,
                    validation: undefined,
                    active,
                    validate: false
                  };
            })
          }
        }}
        onSubmit={submitForm}
        mutators={{
          ...arrayMutators,
          setVendorMarketId: (args, state, utils) => {
            utils.changeValue(state, 'vendorMarketId', () => args[0]);
          },
          useShippingConfig: (args, state, utils) => {
            utils.changeValue(state, 'useShippingConfig', () => args[0]);
          },
          useOchConfig: (args, state, utils) => {
            utils.changeValue(state, 'useOchConfig', () => args[0]);
          },
          useChannelAdvisorConfig: (args, state, utils) => {
            utils.changeValue(state, 'useChannelAdvisorConfig', () => args[0]);
          },
          usePepdirectConfig: (args, state, utils) => {
            utils.changeValue(state, 'usePepdirectConfig', () => args[0]);
          },
          useEdiConfigInternal: (args, state, utils) => {
            utils.changeValue(state, 'useEdiConfigInternal', () => args[0]);
          },
          useEdiConfigExternal: (args, state, utils) => {
            utils.changeValue(state, 'useEdiConfigExternal', () => args[0]);
          },
          useEmailConfigSalesOrderRelease: (args, state, utils) => {
            utils.changeValue(state, 'useEmailConfigSalesOrderRelease', () => args[0]);
          },
          useEmailConfigSalesOrderExport: (args, state, utils) => {
            utils.changeValue(state, 'useEmailConfigSalesOrderExport', () => args[0]);
          },
          useInternalAlertEmailConfig: (args, state, utils) => {
            utils.changeValue(state, 'useInternalAlertEmailConfig', () => args[0]);
          },
          useInternalAlertConfig: (args, state, utils) => {
            utils.changeValue(state, 'useInternalAlertConfig', () => args[0]);
          },
          useAmazonSellingPartnerConfig: (args, state, utils) => {
            utils.changeValue(state, 'useAmazonSellingPartnerConfig', () => args[0]);
          },
          useSamsClubConfig: (args, state, utils) => {
            utils.changeValue(state, 'useSamsClubConfig', () => args[0]);
          },
          useEdiMessageConfiguration: (args, state, utils) => {
            utils.changeValue(state, 'useEdiMessageConfiguration', () => args[0]);
          },
          usePurchaseOrderCustomization850: (args, state, utils) => {
            utils.changeValue(state, 'usePurchaseOrderCustomization850', () => args[0]);
          },
          usePurchaseOrderAckCustomization855: (args, state, utils) => {
            utils.changeValue(state, 'usePurchaseOrderAckCustomization855', () => args[0]);
          },
          usePurchaseOrderCustomization856: (args, state, utils) => {
            utils.changeValue(state, 'usePurchaseOrderCustomization856', () => args[0]);
          },
          usePurchaseOrderCustomization810: (args, state, utils) => {
            utils.changeValue(state, 'usePurchaseOrderCustomization810', () => args[0]);
          },
          setAssortmentValidation: (args, state, utils) => {
            utils.changeValue(state, `${args[0]}.validation`, () => args[1]);
            if (args[1]) {
              utils.changeValue(state, `${args[0]}.required`, () => true);
            }
          },
          unselectConfigField: (args, state, utils) => {
            const fields = get(
              state.formState.values,
              'assortmentConfig.fields'
            ) as unknown as AssortmentFormField[];
            const fieldIndex = fields.findIndex((f) => f.slug === args[0]);
            utils.changeValue(state, `assortmentConfig.fields[${fieldIndex}].active`, () => false);
            utils.changeValue(
              state,
              `assortmentConfig.fields[${fieldIndex}].required`,
              () => false
            );
            utils.changeValue(
              state,
              `assortmentConfig.fields[${fieldIndex}].validate`,
              () => false
            );
            utils.changeValue(state, `assortmentConfig.fields[${fieldIndex}].name`, () =>
              getDefaultFieldTitles(args[0])
            );
            // default validation will be added as a callback to the validate field change
          },
          selectConfigField: (args, state, utils) => {
            const fields = get(
              state.formState.values,
              'assortmentConfig.fields'
            ) as unknown as AssortmentFormField[];
            const fieldIndex = fields.findIndex((f) => f.slug === args[0]);
            utils.changeValue(state, `assortmentConfig.fields[${fieldIndex}].active`, () => true);
            utils.changeValue(state, `assortmentConfig.fields[${fieldIndex}].required`, () => true);
            utils.changeValue(state, `assortmentConfig.fields[${fieldIndex}].validate`, () => true);
            utils.changeValue(state, `assortmentConfig.fields[${fieldIndex}].name`, () =>
              getDefaultFieldTitles(args[0])
            );
            // default validation will be added as a callback to the validate field change
          },
          setAssortmentName: (args, state, utils) => {
            utils.changeValue(state, `${args[0]}.name`, () => args[1]);
          },
          setSecretValue: (args, state, utils) => {
            utils.changeValue(state, args[1], () => args[0]);
          },
          setStoreConfigRequiredIds: (args, state, utils) => {
            utils.changeValue(state, 'storeConfigRequiredIds', () => args[0]);
          },
          setShowAdditionalFields: (args, state, utils) => {
            utils.changeValue(state, 'showAdditionalFields', () => args[0]);
          },
          setSourcingRules: (args, state, utils) => {
            utils.changeValue(state, 'sourcingRules', () => args[0]);
          },
          setRdds: (args, state, utils) => {
            utils.changeValue(state, 'rdds', () => args[0]);
          }
        }}
        render={(
          { handleSubmit, form } //TODO: add if errors for shipping sla here -- maybe not needed here because sla isn't required field?
        ) => (
          <form
            id="edit-trading-partner-modal-form"
            onSubmit={(event) => {
              const { errors } = form.getState();
              if (tradingPartner?.id && errors && !!Object.keys(errors).length) {
                if (
                  errors['businessUnitId'] ||
                  errors['deliveryType'] ||
                  errors['externalId'] ||
                  errors['name'] ||
                  errors['purchaseOrderAcknowledgementTransport'] ||
                  errors['shipToReleaseIdentifier'] ||
                  errors['vendorMarketId'] ||
                  errors['measurementSystem'] ||
                  errors['ingestPo'] ||
                  errors['storeConfigRequiredIds'] ||
                  errors['sourcingRules']
                ) {
                  message.warning('Please fill all required fields on the General Settings tab');
                } else if (
                  errors['channelAdvisorConfigProfileId'] ||
                  errors['channelAdvisorConfigSiteId'] ||
                  errors['channelAdvisorConfigSiteName'] ||
                  errors['ochConfigScope'] ||
                  errors['pepdirectConfigAppId'] ||
                  errors['shippingConfigMaximalPickupDays'] ||
                  errors['shippingConfigMinimalPickupDays'] ||
                  errors['ediConfigInternalGsLocalCode'] ||
                  errors['ediConfigInternalGsRemoteCode'] ||
                  errors['ediConfigInternalIsaLocalId'] ||
                  errors['ediConfigInternalIsaLocalQualifier'] ||
                  errors['ediConfigInternalIsaRemoteId'] ||
                  errors['ediConfigInternalIsaRemoteQualifier'] ||
                  errors['ediConfigExternalGsLocalCode'] ||
                  errors['ediConfigExternalGsRemoteCode'] ||
                  errors['ediConfigExternalIsaLocalId'] ||
                  errors['ediConfigExternalIsaLocalQualifier'] ||
                  errors['ediConfigExternalIsaRemoteId'] ||
                  errors['ediConfigExternalIsaRemoteQualifier'] ||
                  errors['ediMessageConfigurationExternalProductId'] ||
                  errors['ediMessageConfigurationInternalProductId'] ||
                  errors['ediMessageConfigurationFormat'] ||
                  errors['ediMessageConfigurationGsLocalCode'] ||
                  errors['ediMessageConfigurationGsRemoteCode'] ||
                  errors['ediMessageConfigurationIsaInterchangeUsageIndicator'] ||
                  errors['ediMessageConfigurationIsaComponentElementSeparator'] ||
                  errors['ediMessageConfigurationIsaInterchangeControlVersionNumber'] ||
                  errors['ediMessageConfigurationIsaLocalId'] ||
                  errors['ediMessageConfigurationIsaLocalQualifier'] ||
                  errors['ediMessageConfigurationIsaRemoteId'] ||
                  errors['ediMessageConfigurationIsaRemoteQualifier'] ||
                  errors['ediMessageConfigurationIsaRepetitionSeparator'] ||
                  errors['ediMessageConfigurationSupplierId'] ||
                  errors['ediMessageConfigurationSupplierIdPath'] ||
                  errors['ediMessageConfigurationWebhookEndpoint'] ||
                  errors['purchaseOrderCustomization850ConfigShipTo'] ||
                  errors['purchaseOrderCustomization850ConfigProductUnit'] ||
                  errors['purchaseOrderCustomization850ConfigPassUpc'] ||
                  errors['purchaseOrderAckCustomization855ConfigItemNotAvailInput'] ||
                  errors['purchaseOrderAckCustomization855ConfigItemInvalidInput'] ||
                  errors['purchaseOrderAckCustomization855ConfigMatch'] ||
                  errors['purchaseOrderCustomization856ConfigMatch'] ||
                  errors['purchaseOrderCustomization856ConfigPassShipTo'] ||
                  errors['purchaseOrderCustomization810ConfigResName'] ||
                  errors['purchaseOrderCustomization810ConfigResAddress'] ||
                  errors['purchaseOrderCustomization810ConfigResCityZip'] ||
                  errors['purchaseOrderCustomization810ConfigMatch'] ||
                  errors['purchaseOrderCustomization810ConfigPassRemAddress'] ||
                  errors['purchaseOrderCustomization810ConfigPassBillToAddress'] ||
                  errors['emailConfigSalesOrderExportEmailFromAddress'] ||
                  errors['emailConfigSalesOrderExportEmailToList'] ||
                  errors['emailConfigSalesOrderReleaseEmailFromAddress'] ||
                  errors['emailConfigSalesOrderReleaseEmailToList'] ||
                  errors['internalAlertEmailConfigEmailFromAddress'] ||
                  errors['internalAlertEmailConfigEmailToList']
                ) {
                  message.warning('Please fill all required fields on the Integrations tab');
                }
              }
              const promise = handleSubmit(event);
              promise &&
                promise.then(() => {
                  // do nothing
                });
              return promise;
            }}
          >
            {tradingPartner?.id ? (
              <EditTradingPartnerTabs
                tradingPartner={tradingPartner}
                upserting={upserting}
                onClose={onClose}
                defaultSourcingRules={fallbacksData?.tradingPartnerFallbacks?.sourcingRules || []}
                open={!!tradingPartner}
                firstOpenTab={firstOpenTab ?? undefined}
              />
            ) : (
              <AddTradingPartnerStepper
                form={form}
                tradingPartner={tradingPartner}
                setVendorMarketId={(id: string) => {
                  form.mutators.setVendorMarketId(id);
                }}
                upserting={upserting}
                onCancel={onClose}
                defaultSourcingRules={fallbacksData?.tradingPartnerFallbacks?.sourcingRules || []}
                visible={!!tradingPartner}
              />
            )}
          </form>
        )}
      />
    </Spin>
  );
};
