import React, { useState, useContext } from 'react';
import { Button, Col, Collapse, Divider, Row, Switch } from 'antd';
import { SourcingRuleInput, UpsertVendorMarketInput } from 'graphql/__generated__/types';
import { useEnumValue } from 'common/useEnumValue';
import { FinalFormSelect } from 'components/Common/fields/FinalFormSelect/FinalFormSelect';
import { FinalFormSwitch } from 'components/Common/fields/FinalFormSwitch/FinalFormSwitch';
import { composeValidators, parseIntValue, validateRequired } from 'components/Common/fields/Utils';
import { FormApi } from 'final-form';
import { FinalFormInput } from '../../../../../components/Common/fields/FinalFormInput/FinalFormInput';
import { FinalFormInput as FinalFormInputNew } from '../../../../../components/FinalFormFields/FinalFormInput/FinalFormInput';
import { FinalFormSelect as FinalFormSelectNew } from 'components/FinalFormFields/FinalFormSelect/FinalFormSelect';
import s from './GeneralSettingsTab.module.scss';
import { Titles } from 'pages/ShipToPage/helper';
import {
  acronymsToUpperCase,
  camelCaseToSpaces,
  fieldNameToCapitalize
} from 'common/helpers/stringsConverter';
import { OnChange } from 'react-final-form-listeners';
import { EditRetailerChannelModal } from 'pages/RetailerChannelsPage/components/EditRetailerChannel/EditRetailerChannelModal';
import { FieldArray } from 'react-final-form-arrays';
import { PrioritizedList } from 'components/PrioritizedList/PrioritizedList';
import { ValidationMessage } from 'components/Common/fields/ValidationMessage/ValidationMessage';
import { v4 as uuidv4 } from 'uuid';
import { FinalFormMultipleSelect } from 'components/FinalFormFields/FinalFormSelect/FinalFormMultipleSelect';
import { UserContext } from 'context/userContext';
import {
  AllowedDistributionCenter,
  SourcingRule
} from '../../TradingPartnerViewDrawer/TradingPartnerViewDrawer';
import { useForm } from 'react-final-form';
import addIcon from 'assets/icons/editing/add_blue.svg';
import { get } from 'lodash-es';
import { Select } from 'components/alloy/Select/Select';
import { FormInput } from 'components/alloy/formFields/FormInput/FormInput';
import {
  BusinessUnit,
  VendorMarket
} from '../../EditTradingPartnerModal/components/AddTradingPartnerStepper/AddTradingPartnerStepper';

const { Panel } = Collapse;

interface GeneralSettingsTabProps {
  setVendorMarketId: (id: string, form: FormApi) => void;
  upserting: boolean;
  defaultSourcingRules: SourcingRule[];
  allowedDistributionCenters: AllowedDistributionCenter[];
  businessUnits: BusinessUnit[];
  initLoading: boolean;
  vendorMarkets: VendorMarket[];
}

const getOpenPanels = (form: FormApi<any, Partial<any>>) => {
  const keys: string[] = [];
  if (form.getState().values.showProcessingFlow) {
    keys.push('1');
  }
  if (form.getState().values.showStoreConfig) {
    keys.push('2');
  }
  if (form.getState().values.showPoValidationConfig) {
    keys.push('3');
  }
  if (form.getState().values.showSourcingRules) {
    keys.push('4');
  }
  if (form.getState().values.showAdditionalFields) {
    keys.push('5');
  }
  return keys;
};

export const GeneralSettingsTab = ({
  setVendorMarketId,
  upserting,
  defaultSourcingRules,
  allowedDistributionCenters,
  initLoading,
  vendorMarkets,
  businessUnits
}: GeneralSettingsTabProps) => {
  const { user } = useContext(UserContext);

  const form = useForm();

  const { enumValues: releaseMethodValues, loading: releaseMethodValuesLoading } =
    useEnumValue('ReleaseMethod');
  const {
    enumValues: internalProductIdQualifierValues,
    loading: internalProductIdQualifierValuesLoading
  } = useEnumValue('InternalProductIdQualifier');
  const { enumValues: productIdQualifierValues, loading: productIdQualifierValuesLoading } =
    useEnumValue('ProductIdQualifier');
  const {
    enumValues: purchaseOrderAcknowledgementTransportValues,
    loading: purchaseOrderAcknowledgementTransportValuesLoading
  } = useEnumValue('PurchaseOrderAcknowledgementTransport');
  const {
    enumValues: shipToReleaseIdentifierValues,
    loading: shipToReleaseIdentifierValuesLoading
  } = useEnumValue('ShipToReleaseIdentifier');
  const { enumValues: unitOfMeasureValues, loading: unitOfMeasureValuesLoading } =
    useEnumValue('UnitOfMeasure');
  const { enumValues: deliveryTypeValues, loading: deliveryTypeValuesLoading } =
    useEnumValue('DeliveryType');
  const { enumValues: measurementSystemValues, loading: measurementSystemValuesLoading } =
    useEnumValue('MeasurementSystem');
  const { enumValues: ingestPoValues, loading: ingestPoValuesLoading } =
    useEnumValue('ProcessingFlowIngestPo');
  const { enumValues: preparePoValues, loading: preparePoValuesLoading } =
    useEnumValue('ProcessingFlowPreparePo');
  const { enumValues: routeToOptimalDcValues, loading: routeToOptimalDcValuesLoading } =
    useEnumValue('ProcessingFlowRouteToOptimalDc');
  const { enumValues: resetPoValues, loading: resetPoValuesLoading } =
    useEnumValue('ProcessingFlowResetPo');
  const { enumValues: updatePoValues, loading: updatePoValuesLoading } =
    useEnumValue('ProcessingFlowUpdatePo');
  const { enumValues: cancelPoValues, loading: cancelPoValuesLoading } =
    useEnumValue('ProcessingFlowCancelPo');
  const { enumValues: orderItemRoundingValues, loading: orderItemRoundingValuesLoading } =
    useEnumValue('OrderItemRounding');
  const { enumValues: validationLevelValues, loading: validationLevelValuesLoading } =
    useEnumValue('ValidationLevel');
  const { enumValues: releaseAcknowledgementValues, loading: releaseAcknowledgementValuesLoading } =
    useEnumValue('ProcessingFlowReleaseExternalAcknowledgementType');
  const { enumValues: releaseExchangeReturnValues, loading: releaseExchangeReturnValuesLoading } =
    useEnumValue('ProcessingFlowReleaseExternalExchangeReturnType');
  const { enumValues: releaseShipmentValues, loading: releaseShipmentValuesLoading } = useEnumValue(
    'ProcessingFlowReleaseExternalShipmentType'
  );
  const { enumValues: releaseInventoryValues, loading: releaseInventoryValuesLoading } =
    useEnumValue('ProcessingFlowReleaseInventoryType');
  const { enumValues: releaseInvoiceValues, loading: releaseInvoiceValuesLoading } = useEnumValue(
    'ProcessingFlowReleaseInvoiceType'
  );
  const { enumValues: fulfillmentSyncValues, loading: fulfillmentSyncValuesLoading } = useEnumValue(
    'ProcessingFlowFulfillmentSyncType'
  );

  const [showAddRetailerChannelModal, setShowAddRetailerChannelModal] = useState<boolean>(false);

  const generalFields = (
    <>
      <Row className={s.title_row}>Configuration details</Row>
      <Row gutter={16}>
        <Col span={12}>
          <FinalFormInput
            name="externalId"
            title="External ID"
            required
            validate={validateRequired}
          />
          <FinalFormSelect
            loading={initLoading}
            name="vendorMarketId"
            title="Retailer Channel"
            options={vendorMarkets.map((vendorMarket) => ({
              value: vendorMarket.id,
              label: vendorMarket.name
            }))}
            validate={validateRequired}
            showSearch
            sortByName
            required
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0 0' }} />
                <Button
                  type="link"
                  onClick={() => setShowAddRetailerChannelModal(true)}
                  data-testid="add-new-retailer-channel"
                >
                  + Add new Retailer Channel
                </Button>
              </>
            )}
          />
          <OnChange name="vendorMarketId">{() => form.mutators.setRdds([])}</OnChange>
          <FinalFormSelect
            loading={deliveryTypeValuesLoading}
            name="deliveryType"
            title="Delivery Type"
            options={(deliveryTypeValues || []).map((deliveryType: string) => ({
              value: deliveryType,
              label: deliveryType
            }))}
            validate={validateRequired}
            required
          />
          <FinalFormSelect
            loading={shipToReleaseIdentifierValuesLoading}
            name="shipToReleaseIdentifier"
            title="Ship-To Release Identifier"
            required
            validate={validateRequired}
            options={(shipToReleaseIdentifierValues || []).map((identifier: string) => ({
              value: identifier,
              label: getReleaseIdentifierName(identifier)
            }))}
          />
          <OnChange name="shipToReleaseIdentifier">
            {(value: string, previous: string) => {
              if (value) {
                const previousValue: string[] = (
                  form.getState().values.storeConfigRequiredIds || []
                ).filter((value: string | undefined) => !!value);
                const modified = form.getFieldState('shipToReleaseIdentifier')?.modified;
                if (modified) {
                  if (!previousValue.includes(value)) {
                    form.mutators.setStoreConfigRequiredIds([
                      value,
                      ...previousValue.filter((field) => field !== previous)
                    ]);
                  } else {
                    const newRequiredIds = previousValue
                      .filter((field) => field !== previous)
                      .sort((value1, value2) => (value1 === value ? -1 : value2 === value ? 1 : 0));
                    form.mutators.setStoreConfigRequiredIds(newRequiredIds);
                  }
                }
              }
            }}
          </OnChange>
        </Col>
        <Col span={12}>
          <FinalFormInput name="name" title="Name" validate={validateRequired} required />
          <FinalFormSelect
            loading={initLoading}
            name="businessUnitId"
            title="Business Unit"
            options={businessUnits.map((businessUnit) => ({
              value: businessUnit.id || '',
              label: businessUnit.name || ''
            }))}
            validate={validateRequired}
            showSearch
            sortByName
            required
          />
          <FinalFormSelect
            loading={purchaseOrderAcknowledgementTransportValuesLoading}
            name="purchaseOrderAcknowledgementTransport"
            title="Purchase Order Acknowledgement Transport"
            required
            validate={validateRequired}
            options={(purchaseOrderAcknowledgementTransportValues || []).map(
              (transport: string) => ({
                value: transport,
                label: transport
              })
            )}
          />
          <FinalFormSelect
            loading={measurementSystemValuesLoading}
            name="measurementSystem"
            title="Measurement System"
            required
            validate={validateRequired}
            options={(measurementSystemValues || []).map((measurementSystem: string) => ({
              value: measurementSystem,
              label: measurementSystem
            }))}
          />
        </Col>
      </Row>
      <Row className={s.title_row}>Add features</Row>
      <Row gutter={16} className={s.row}>
        <Col span={16}>
          <FinalFormSwitch
            name="sendInitialPurchaseOrderAcknowledgment"
            title="Send Initial Purchase Order Acknowledgment"
            checkbox
            inline
          />
        </Col>
        <Col span={24}>
          <FinalFormSwitch
            name="sendPurchaseOrderProcessingState"
            title="Send Purchase Order Processing State"
            checkbox
            inline
          />
        </Col>
      </Row>
      <Collapse
        bordered={false}
        className={s.collapse}
        activeKey={getOpenPanels(form)}
        onChange={(keys) => {
          form.mutators.setShowAdditionalFields(
            (typeof keys === 'string' ? [keys] : keys).includes('5')
          );
        }}
      >
        <Panel
          header={
            <div className={s.processing_flow}>
              Processing Flow
              <FinalFormSwitch name="showProcessingFlow" title="" stopPropagation={true} />
            </div>
          }
          key="1"
        >
          {form.getFieldState('showProcessingFlow')?.value ? (
            <Row gutter={16}>
              <Col span={12}>
                <FinalFormSelect
                  loading={ingestPoValuesLoading}
                  name="ingestPo"
                  title="Ingest PO"
                  options={(ingestPoValues || []).map((ingestPo: string) => ({
                    value: ingestPo,
                    label: ingestPo
                  }))}
                  required
                  validate={(value) => {
                    if (!upserting) {
                      return validateRequired(value);
                    }
                    return undefined;
                  }}
                />
                {user?.availableActions.includes('ROUTE_TO_OPTIMAL_DC') && (
                  <FinalFormSelect
                    loading={routeToOptimalDcValuesLoading}
                    name="routeToOptimalDc"
                    title="Route To Optimal DC"
                    options={(routeToOptimalDcValues || []).map((routeToOptimalDc: string) => ({
                      label: routeToOptimalDc,
                      value: routeToOptimalDc
                    }))}
                    allowClear
                  />
                )}
                <FinalFormSelect
                  loading={cancelPoValuesLoading}
                  name="cancelPo"
                  title="Cancel PO"
                  options={(cancelPoValues || []).map((cancelPo: string) => ({
                    value: cancelPo,
                    label: cancelPo
                  }))}
                  allowClear
                />
                <FinalFormSelect
                  loading={releaseAcknowledgementValuesLoading}
                  name="releaseAcknowledgement"
                  title="Release Acknowledgement"
                  options={(releaseAcknowledgementValues || []).map(
                    (releaseAcknowledgement: string) => ({
                      value: releaseAcknowledgement,
                      label: releaseAcknowledgement
                    })
                  )}
                  allowClear
                />
                <FinalFormSelect
                  loading={releaseShipmentValuesLoading}
                  name="releaseShipment"
                  title="Release Shipment"
                  options={(releaseShipmentValues || []).map((releaseShipment: string) => ({
                    value: releaseShipment,
                    label: releaseShipment
                  }))}
                  allowClear
                />
                <FinalFormSelect
                  loading={releaseInvoiceValuesLoading}
                  name="releaseInvoice"
                  title="Release Invoice"
                  options={(releaseInvoiceValues || []).map((releaseInvoice) => ({
                    value: releaseInvoice,
                    label: releaseInvoice
                  }))}
                  allowClear
                />
                <FinalFormSwitch checkbox inline name="autoRelease" title="Auto Release" />
              </Col>
              <Col span={12}>
                {user?.availableActions.includes('PREPARE_PO') && (
                  <FinalFormSelect
                    loading={preparePoValuesLoading}
                    name="preparePo"
                    title="Prepare PO"
                    options={(preparePoValues || []).map((preparePo: string) => ({
                      value: preparePo,
                      label: preparePo
                    }))}
                    allowClear
                  />
                )}
                <FinalFormSelect
                  loading={updatePoValuesLoading}
                  name="updatePo"
                  title="Update PO"
                  options={(updatePoValues || []).map((updatePo: string) => ({
                    value: updatePo,
                    label: updatePo
                  }))}
                  allowClear
                />
                <FinalFormSelect
                  loading={resetPoValuesLoading}
                  name="resetPo"
                  title="Reset PO"
                  options={(resetPoValues || []).map((resetPo: string) => ({
                    label: resetPo,
                    value: resetPo
                  }))}
                  allowClear
                />
                <FinalFormSelect
                  loading={releaseExchangeReturnValuesLoading}
                  name="releaseExchangeReturn"
                  title="Release Exchange Return"
                  options={(releaseExchangeReturnValues || []).map(
                    (releaseExchangeReturn: string) => ({
                      value: releaseExchangeReturn,
                      label: releaseExchangeReturn
                    })
                  )}
                  allowClear
                />
                <FinalFormSelect
                  loading={releaseInventoryValuesLoading}
                  name="releaseInventory"
                  title="Release Inventory"
                  options={(releaseInventoryValues || []).map((releaseInventory: string) => ({
                    value: releaseInventory,
                    label: releaseInventory
                  }))}
                  allowClear
                />
                <FinalFormSelect
                  loading={fulfillmentSyncValuesLoading}
                  name="fulfillmentSync"
                  title="Fulfillment Sync"
                  options={(fulfillmentSyncValues || []).map((fulfillmentSync) => ({
                    value: fulfillmentSync,
                    label: fulfillmentSync
                  }))}
                  allowClear
                />
              </Col>
            </Row>
          ) : (
            <div className={s.not_active}>Not active</div>
          )}
        </Panel>
        <Panel
          header={
            <div className={s.processing_flow}>
              Ship-To Required Fields
              <Switch
                checked={true}
                onClick={(checked, event) => {
                  event.stopPropagation();
                }}
              />
            </div>
          }
          key="2"
        >
          <Row>
            <Col span={24}>
              <FinalFormMultipleSelect
                loading={shipToReleaseIdentifierValuesLoading}
                name="storeConfigRequiredIds"
                data-testid="storeConfigRequiredIds"
                label="Required IDs"
                options={(shipToReleaseIdentifierValues || []).map((identifier) => ({
                  value: identifier,
                  label: getReleaseIdentifierName(identifier),
                  disabled: (() => {
                    const storeConfigRequiredIds =
                      form.getFieldState('storeConfigRequiredIds')?.value || [];
                    const shipToReleaseIdentifier =
                      form.getFieldState('shipToReleaseIdentifier')?.value;
                    return (
                      identifier === shipToReleaseIdentifier &&
                      storeConfigRequiredIds.includes(shipToReleaseIdentifier)
                    );
                  })()
                }))}
                required
                validate={
                  !upserting
                    ? composeValidators(
                        Array.of<any>(validateRequired, (value: string | string[]) => {
                          const shipToReleaseIdentifier =
                            form.getFieldState('shipToReleaseIdentifier')?.value;
                          const requiredIds: string[] = typeof value === 'string' ? [value] : value;
                          if (!requiredIds.includes(shipToReleaseIdentifier)) {
                            return 'Ship-To Release Identifier should be required';
                          }
                          return null;
                        })
                      )
                    : undefined
                }
              />
            </Col>
          </Row>
        </Panel>
        <Panel
          header={
            <div className={s.processing_flow}>
              PO Validation Config
              <FinalFormSwitch name="showPoValidationConfig" title="" stopPropagation={true} />
            </div>
          }
          key="3"
        >
          {form.getFieldState('showPoValidationConfig')?.value ? (
            <Row gutter={8}>
              <Col span={12}>
                <FinalFormSelect
                  name="poValidationConfigPriceMismatch"
                  title="Price Mismatch"
                  loading={validationLevelValuesLoading}
                  options={(validationLevelValues || []).map((level) => ({
                    value: level,
                    label: level
                  }))}
                  required
                  validate={!upserting ? validateRequired : undefined}
                />
                <FinalFormSelect
                  name="poValidationConfigUnderMoq"
                  title="Under MOQ"
                  loading={validationLevelValuesLoading}
                  options={(validationLevelValues || []).map((level) => ({
                    value: level,
                    label: level
                  }))}
                  required
                  validate={!upserting ? validateRequired : undefined}
                />
              </Col>
              <Col span={12}>
                <FinalFormSelect
                  name="poValidationConfigWrongUom"
                  title="Wrong UOM"
                  loading={validationLevelValuesLoading}
                  options={(validationLevelValues || []).map((level) => ({
                    value: level,
                    label: level
                  }))}
                  required
                  validate={!upserting ? validateRequired : undefined}
                />
              </Col>
            </Row>
          ) : (
            <div className={s.not_active}>Not active</div>
          )}
        </Panel>
        <Panel
          header={
            <div className={s.processing_flow}>
              Release Rules
              <FinalFormSwitch name="showSourcingRules" title="" stopPropagation={true} />
            </div>
          }
          key="4"
        >
          {form.getFieldState('showSourcingRules')?.value ? (
            <>
              <FieldArray
                name="sourcingRules"
                validate={(value) => {
                  const error = (value || []).some(
                    (item) => !item.description || !item.releaseMethod
                  );
                  return error ? 'Name and Release Method are required for the Release Rule' : null;
                }}
              >
                {({ fields, meta }) => {
                  const onChange = (newSelectedItems: (SourcingRuleInput & { key: string })[]) => {
                    form.mutators.setSourcingRules(
                      newSelectedItems
                        .filter(
                          (item) =>
                            !item.id || !defaultSourcingRules.map((dsr) => dsr.id).includes(item.id)
                        )
                        .map((item, index) => ({
                          ...item,
                          priority: index + 1
                        }))
                    );
                  };

                  const getItemBodyRender = (
                    item: SourcingRuleInput & { finalFormParentName?: string },
                    index: number,
                    disabled: boolean
                  ) => (
                    <div>
                      <div className={s.sourcing_rule_row}>
                        {item.finalFormParentName ? (
                          <FinalFormInputNew
                            name={`${item.finalFormParentName}.description`}
                            className={s.name_field}
                            placeholder="Name"
                            disabled={disabled}
                            hideFieldInfo
                            validate={validateRequired}
                          />
                        ) : (
                          <FormInput
                            value={item.description}
                            className={s.name_field}
                            placeholder="Name"
                            disabled={disabled}
                            hideFieldInfo
                          />
                        )}
                        {item.finalFormParentName ? (
                          <FinalFormSelectNew
                            name={`${item.finalFormParentName}.distributionCenterId`}
                            className={s.dc_field}
                            disabled={disabled}
                            options={allowedDistributionCenters
                              ?.map((dc) => ({
                                label: `${dc.name} (${dc.code})`,
                                value: dc.id
                              }))
                              .sort((dc1, dc2) => dc1.label.localeCompare(dc2.label))}
                            allowClear
                            hideFieldInfo={true}
                          />
                        ) : (
                          <Select
                            value={item.distributionCenterId ?? undefined}
                            className={s.dc_field}
                            disabled={disabled}
                            options={allowedDistributionCenters
                              ?.map((dc) => ({
                                label: `${dc.name} (${dc.code})`,
                                value: dc.id
                              }))
                              .sort((dc1, dc2) => dc1.label.localeCompare(dc2.label))}
                            allowClear
                          />
                        )}
                      </div>
                      <div className={s.sourcing_rule_row}>
                        <span className={s.weight_title}>Weight Limit</span>
                        {item.finalFormParentName ? (
                          <FinalFormInputNew
                            name={`${item.finalFormParentName}.minWeightLbs`}
                            className={s.weight_field}
                            disabled={disabled}
                            placeholder="0"
                            suffix="Lb"
                            hideFieldInfo
                            parse={parseIntValue}
                            validate={(value) => {
                              if (value && Number(value) < 0) return 'Should not be less than 0';
                              return null;
                            }}
                          />
                        ) : (
                          <FormInput
                            value={item.minWeightLbs ? `${item.minWeightLbs}` : undefined}
                            className={s.weight_field}
                            disabled={disabled}
                            placeholder="0"
                            suffix="Lb"
                            hideFieldInfo
                          />
                        )}
                        {item.finalFormParentName ? (
                          <FinalFormInputNew
                            name={`${item.finalFormParentName}.maxWeightLbs`}
                            className={s.weight_field}
                            disabled={disabled}
                            placeholder="0"
                            suffix="Lb"
                            hideFieldInfo
                            parse={parseIntValue}
                            validate={(value, allValues) => {
                              if (!value) return null;
                              if (Number(value) < 0) return 'Should not be less than 0';
                              if (
                                get(allValues, `${item.finalFormParentName}.minWeightLbs`) &&
                                Number(get(allValues, `${item.finalFormParentName}.minWeightLbs`)) >
                                  Number(value)
                              )
                                return 'Should not be less than Min Weight';
                              return null;
                            }}
                          />
                        ) : (
                          <FormInput
                            value={item.maxWeightLbs ? `${item.maxWeightLbs}` : undefined}
                            className={s.weight_field}
                            disabled={disabled}
                            placeholder="0"
                            suffix="Lb"
                            hideFieldInfo
                          />
                        )}
                        {item.finalFormParentName ? (
                          <FinalFormSelectNew
                            name={`${item.finalFormParentName}.releaseMethod`}
                            className={s.release_method_field}
                            disabled={disabled}
                            options={releaseMethodValues?.map((value) => ({
                              label: value,
                              value: value
                            }))}
                            validate={validateRequired}
                            hideFieldInfo={true}
                          />
                        ) : (
                          <Select
                            value={item.releaseMethod}
                            className={s.release_method_field}
                            disabled={disabled}
                            options={releaseMethodValues?.map((value) => ({
                              label: value,
                              value: value
                            }))}
                          />
                        )}
                      </div>
                    </div>
                  );

                  return (
                    <>
                      <div className={s.sourcing_rules_container}>
                        <PrioritizedList<
                          SourcingRuleInput & { key: string; finalFormParentName?: string }
                        >
                          data-testid="edit-trading-partner-soucing-rules-list"
                          selected={[
                            ...(fields.value || [])
                              .sort((dc1, dc2) => dc1.priority - dc2.priority)
                              .map((item, i) => ({
                                ...item,
                                key: item.key || item.id,
                                finalFormParentName: `sourcingRules[${i}]`
                              })),
                            ...(defaultSourcingRules || []).map((item) => ({
                              ...item,
                              key: item.id
                            }))
                          ]}
                          onChange={onChange}
                          itemBodyRender={(item, index, disabled) =>
                            getItemBodyRender(item, index, disabled)
                          }
                          itemKey={(item) => item.key}
                          disabledItems={defaultSourcingRules.map((dsr) => dsr.id)}
                          overrideDisabledIndex={(item) => item.priority - 1}
                        />
                        <div className={s.add_sourcing_rule_button}>
                          <button
                            onClick={() => {
                              form.mutators.setSourcingRules([
                                ...(fields.value || []).sort(
                                  (dc1, dc2) => dc1.priority - dc2.priority
                                ),
                                {
                                  priority: fields.length,
                                  key: uuidv4()
                                }
                              ]);
                            }}
                          >
                            Add Rule
                          </button>
                          <img alt="" src={addIcon} />
                        </div>
                      </div>
                      <ValidationMessage meta={meta} />
                    </>
                  );
                }}
              </FieldArray>
            </>
          ) : (
            <div className={s.not_active}>Not active</div>
          )}
        </Panel>
        <Panel header="Additional settings" key="5">
          <Row gutter={16}>
            <Col span={12}>
              <FinalFormSelect
                loading={unitOfMeasureValuesLoading}
                name="incomingUnitOfMeasurementOverride"
                title="Incoming Unit Of Measurement Override"
                allowClear
                options={(unitOfMeasureValues || []).map((unit: string) => ({
                  value: unit,
                  label: unit
                }))}
              />
              <FinalFormSelect
                loading={unitOfMeasureValuesLoading}
                name="outgoingUnitOfMeasurementOverride"
                title="Outgoing Unit Of Measurement Override"
                allowClear
                options={(unitOfMeasureValues || []).map((unit: string) => ({
                  value: unit,
                  label: unit
                }))}
              />
              <FinalFormSelect
                loading={releaseMethodValuesLoading}
                name="releaseMethod"
                title="Release Method"
                allowClear
                options={(releaseMethodValues || []).map((method: string) => ({
                  value: method,
                  label: method
                }))}
              />
            </Col>
            <Col span={12}>
              <FinalFormSelect
                loading={internalProductIdQualifierValuesLoading}
                name="internalProductIdQualifier"
                title="Internal Product ID Qualifier"
                allowClear
                options={(internalProductIdQualifierValues || []).map((qualifier: string) => ({
                  value: qualifier,
                  label: qualifier
                }))}
              />
              <FinalFormSelect
                loading={productIdQualifierValuesLoading}
                name="productIdQualifier"
                title="Product ID Qualifier"
                allowClear
                options={(productIdQualifierValues || []).map((qualifier: string) => ({
                  value: qualifier,
                  label: qualifier
                }))}
              />
              <FinalFormSelect
                loading={orderItemRoundingValuesLoading}
                name="orderItemRounding"
                title="Order Item Rounding"
                options={(orderItemRoundingValues || []).map((value: string) => ({
                  value: value,
                  label: value
                }))}
                required
                validate={validateRequired}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );

  return (
    <>
      <div>{generalFields}</div>
      {showAddRetailerChannelModal && (
        <EditRetailerChannelModal
          vendorMarket={{} as UpsertVendorMarketInput}
          onClose={(id?: string) => {
            if (id) {
              setVendorMarketId(id, form);
            }
            setShowAddRetailerChannelModal(false);
          }}
        />
      )}
    </>
  );
};

export const getReleaseIdentifierName = (identifier: string): string => {
  const id = Object.keys(Titles).find((titleId) => titleId === identifier);
  if (id) {
    return Titles[id as keyof typeof Titles];
  }
  return acronymsToUpperCase(camelCaseToSpaces(fieldNameToCapitalize(identifier)));
};
